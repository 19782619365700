<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-6">
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <!-- <a-button type="default" @click="resetAndQuery">重置</a-button> -->
              </div>
            </a-form-item>
          </a-form>
        </div>

        <div class="queryView-main">
          <a-table
            class="queryView-table"
            :rowKey="$useRowIndex"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import moment from 'moment'
export default {
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '用户名',
        width: '10%',
        align: 'center',
        dataIndex: 'username',
      },
      {
        title: '问题类型',
        width: '10%',
        align: 'center',
        dataIndex: 'type',
        customRender: (text) => {
          return <div>{this.translateType(text, 'type')}</div>
        },
      },
      {
        title: '问题描述',
        width: '15%',
        align: 'center',
        dataIndex: 'remark',
      },
      {
        title: '图片',
        width: '6%',
        align: 'center',
        dataIndex: 'picUrl',
        customRender: (text) => {
          return <auto-img style="width:100px;height:75px;" preview src={text}></auto-img>
        },
      },

      {
        title: '创建时间',
        width: '10%',
        align: 'center',
        dataIndex: 'createTime',
        customRender: (text) => {
          return <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
    }
  },
  computed: {
    typesMap() {
      return {
        type: [
          {
            label: '综合门户',
            value: 'DJ',
          },
          {
            label: '工作台',
            value: 'GZ',
          },
          {
            label: '数据查询',
            value: 'SJ',
          },
          {
            label: '智能BI',
            value: 'BI',
          },
          {
            label: '工期',
            value: 'GQ',
          },
          {
            label: '应用系统',
            value: 'YY',
          },
          {
            label: '其他',
            value: 'QT',
          },
        ],
      }
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const params = this.getPageParams(_pageNo, _pageSize)
        const res = await this.$axios.post('/unify-home/idea/listPage', {
          ...params,
        })
        const list = res.data.data
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
  },
  async mounted() {
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
